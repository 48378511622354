<!--
 * @Description: 轮播图片组件编辑
 * @Author: 琢磨先生
 * @Date: 2022-06-15 15:18:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-16 03:47:16
-->


<template>
  <div>
    <el-popover placement="left" :width="600" trigger="click" @hide="onHide">
      <template #reference>
        <el-carousel height="150px">
          <el-carousel-item v-for="item in form.items" :key="item">
            <el-image :src="item.pic_url" fit="scale-down"></el-image>
          </el-carousel-item>
        </el-carousel>
      </template>

      <el-table :data="form.items" height="600px">
        <el-table-column label="" width="140">
          <template #header>
            <el-button icon="plus" size="small" round @click="addItem"
              >添加</el-button
            >
          </template>
          <template #default="scope">
            <div>{{ scope.$index + 1 }}</div>
            <upload-avatar
              :src="scope.row.pic_url"
              :hook="scope.row"
              :aspectRatio="700 / 300"
              @change="uploadChange"
            ></upload-avatar>
          </template>
        </el-table-column>

        <el-table-column label="">
          <template #header> 图片要求686x300 </template>
          <template #default="scope">
            <el-form-item label="链接类型">
              <el-radio-group v-model="scope.row.link_type">
                <el-radio :label="0">无</el-radio>
                <el-radio :label="1">类目</el-radio>
                <el-radio :label="2">商品</el-radio>
                <el-radio :label="3">链接</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="值">
              <el-input
                v-model="scope.row.value"
                :placeholder="
                  scope.row.link_type == 1
                    ? '请填写类目id'
                    : scope.row.link_type == 2
                    ? '请填写商品id'
                    : scope.row.link_type == 3
                    ? '请填写链接地址'
                    : ''
                "
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="" width="40">
          <template #default="scope">
            <el-icon
              @click="removeItem(scope.$index)"
              color="#F56C6C"
              :size="25"
              ><Remove
            /></el-icon>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
  </div>
</template>

<script>
import UploadAvatar from "@/views/upload/upload_avatar";
export default {
  components: {
    UploadAvatar,
  },

  data() {
    return {
      form: [],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 添加项
     */
    addItem() {
      this.form.items.push({
        title: "",
        pic_url: "",
        link_type: 0,
        value: "",
      });
    },
    /**
     * 移除
     */
    removeItem(i) {
      this.form.items.splice(i, 1);
    },
    /**
     *
     */
    onHide() {
      this.$emit("change", this.form);
    },
    /**
     * 图片上传回调
     * @param {*} url
     * @param {*} row
     */
    uploadChange(url, row) {
      row.pic_url = url;
    },
  },
};
</script>

<style scoped>
.el-carousel {
  background-color: #f2f6fc;
  position: relative;
}
.el-carousel::after {
  display: block;
  content: "轮播图模块";
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>