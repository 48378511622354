<!--
 * @Description: 单图组件
 * @Author: 琢磨先生
 * @Date: 2022-06-15 15:20:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-16 03:47:09
-->

<template>
  <div>
    <el-popover placement="left" :width="600" trigger="click" @hide="onHide">
      <template #reference>
        <div style="height: 105px">
          <el-image
            style="height: 100%; width: 100%"
            :src="form.items[0].pic_url"
            fit="scale-down"
          ></el-image>
        </div>
      </template>
      <el-table :data="form.items" >
        <el-table-column label="" width="140">
          <template #header> 图片要求700x210 </template>
          <template #default="scope">
            <upload-avatar
              :src="scope.row.pic_url"
              :hook="scope.row"
              :aspectRatio="700 / 210"
              @change="uploadChange"
            ></upload-avatar>
          </template>
        </el-table-column>

        <el-table-column label="">
          <template #default="scope">
            <el-form-item label="链接类型">
              <el-radio-group v-model="scope.row.link_type">
                <el-radio :label="0">无</el-radio>
                <el-radio :label="1">类目</el-radio>
                <el-radio :label="2">商品</el-radio>
                <el-radio :label="3">链接</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="值">
              <el-input
                v-model="scope.row.value"
                :placeholder="
                  scope.row.link_type == 1
                    ? '请填写类目id'
                    : scope.row.link_type == 2
                    ? '请填写商品id'
                    : scope.row.link_type == 3
                    ? '请填写链接地址'
                    : ''
                "
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="" width="40">
          <template #default="scope">
            <el-icon
              @click="removeItem(scope.$index)"
              color="#F56C6C"
              :size="25"
              ><Remove
            /></el-icon>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
  </div>
</template>

<script>
import UploadAvatar from "@/views/upload/upload_avatar";
export default {
  components: {
    UploadAvatar,
  },

  data() {
    return {
      form: [],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
          if (this.form.items.length == 0) {
            this.addItem();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 添加项
     */
    addItem() {
      this.form.items.push({
        title: "",
        pic_url: "",
        link_type: 0,
        value: "",
      });
    },
    /**
     * 移除
     */
    removeItem(i) {
      this.form.items.splice(i, 1);
    },

    /**
     * 图片上传回调
     * @param {*} url
     * @param {*} row
     */
    uploadChange(url, row) {
      row.pic_url = url;
    },
    /**
     * 弹框隐藏后回调
     */
    onHide() {
      this.$emit("change", this.form);
    },
  },
};
</script>

<style scoped>
</style>