<!--
 * @Description: 导航组件
 * @Author: 琢磨先生
 * @Date: 2022-06-15 15:19:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-16 20:23:19
-->

<template>
  <div>
    <el-popover placement="left" :width="600" trigger="click" @hide="onHide">
      <template #reference>
        <div class="grid">
          <div class="item" v-for="item in this.form.items" :key="item">
            <div>
              <el-image
                style="width: 50; height: 50"
                :src="item.pic_url"
              ></el-image>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </template>

      <el-table :data="form.items" height="600px">
        <el-table-column label="" width="140">
          <template #header>
            <el-button icon="plus" size="small" round @click="addItem"
              >添加</el-button
            >
          </template>
          <template #default="scope">
            <div>{{ scope.$index + 1 }}</div>
            <upload-avatar
             :src="scope.row.pic_url"
              :hook="scope.row"
              :aspectRatio="1"
              @change="uploadChange"
            ></upload-avatar>
          </template>
        </el-table-column>

        <el-table-column label="">
          <template #header> 图片要求200x200 </template>
          <template #default="scope">
            <el-form-item label="标题">
              <el-input v-model="scope.row.title" placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="链接类型">
              <el-radio-group v-model="scope.row.link_type">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">类目</el-radio>
                <el-radio label="2">商品</el-radio>
                <el-radio label="3">链接</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="值">
              <el-input
                v-model="scope.row.value"
                :placeholder="
                  scope.row.link_type == 1
                    ? '请填写类目id'
                    : scope.row.link_type == 2
                    ? '请填写商品id'
                    : scope.row.link_type == 3
                    ? '请填写链接地址'
                    : ''
                "
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="" width="40">
          <template #default="scope">
            <el-icon
              @click="removeItem(scope.$index)"
              color="#F56C6C"
              :size="25"
              ><Remove
            /></el-icon>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
  </div>
</template>

<script>
import UploadAvatar from "@/views/upload/upload_avatar";
export default {
  components: {
    UploadAvatar,
  },

  data() {
    return {
      form: [],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 添加项
     */
    addItem() {
      this.form.items.push({
        title: "",
        pic_url: "",
        link_type: 0,
        value: "",
      });
    },
    /**
     * 移除
     */
    removeItem(i) {
      this.form.items.splice(i, 1);
    },

    /**
     * 图片上传回调
     * @param {*} url
     * @param {*} row
     */
    uploadChange(url, row) {
      row.pic_url = url;
    },
    /**
     * 弹框隐藏后回调
     */
    onHide() {
      this.$emit("change", this.form);
    },
  },
};
</script>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  min-height: 100px;
  background-color: #d3dce6;
}
.grid .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid::after {
  display: block;
  content: "导航模块";
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>