<!--
 * @Description: 小程序首页内容
 * @Author: 琢磨先生
 * @Date: 2022-06-10 02:01:35
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-16 20:29:10
-->

<template>
  <el-card class="box">
    <el-select v-model="version_id" placeholder="" @change="versionChange">
      <el-option
        :label="item.version_name"
        :value="item.id"
        v-for="item in versionList"
        :key="item.id"
        >{{ item.version_name }}
        <el-tag type="success" v-if="item.is_enabled" size="small"
          >已启用</el-tag
        >
      </el-option>
    </el-select>
    <el-button
      type="primary"
      round
      style="margin-left: 20px"
      v-if="current_version && !current_version.is_enabled"
      >启用"{{ current_version?.version_name }}"</el-button
    >
  </el-card>
  <el-card class="box" v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <div style="margin-bottom: 20px; font-size: 16px">小程序首页</div>
        <el-table :data="moduleList" border>
          <el-table-column
            label="序号"
            prop="sequence"
            width="100"
          ></el-table-column>
          <el-table-column
            label="模块"
            prop="type_text"
            width="160"
          ></el-table-column>
          <el-table-column label="标题" prop="title"></el-table-column>
          <el-table-column width="180">
            <template #header>
              <el-button
                type="success"
                size="small"
                round
                icon="plus"
                @click="addModule"
                >添加模块</el-button
              >
            </template>
            <template #default="scope">
              <el-popconfirm
                v-if="!scope.row.is_super"
                title="您确定要删除？"
                @confirm="onDelete(scope.row)"
              >
                <template #reference>
                  <el-button size="small" type="danger" link class="btn-del"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12" :offset="0">
        <div style="margin-bottom: 20px; font-size: 16px">生活馆主页</div>
        <el-button
          type="primary" link
          size="small"
          @click="mallVisible = true"
          icon="plus"
          >添加模块</el-button
        >
        <div class="screen">
          <el-scrollbar>
            <draggable v-model="mallModuleList" group="mall" item-key="id">
              <template #item="{ element }">
                <div>
                  <mall-banner
                    v-if="element.type == '0'"
                    :item="element"
                    @change="mallModuleChange"
                  ></mall-banner>
                  <mall-nav
                    :item="element"
                    v-if="element.type == '1'"
                    @change="mallModuleChange"
                  ></mall-nav>
                  <mall-row-image
                    :item="element"
                    v-if="element.type == '2'"
                    @change="mallModuleChange"
                  ></mall-row-image>
                  <mall-rec-goods
                    :item="element"
                    v-if="element.type == '3'"
                  ></mall-rec-goods>
                </div>
              </template>
            </draggable>
          </el-scrollbar>
        </div>

        <el-row style="margin-top: 20px">
          <el-button type="primary" @click="onSaveMall">保存</el-button>
        </el-row>
      </el-col>
    </el-row>
  </el-card>

  <el-dialog
    :title="`添加【${current_version.version_name}】模块`"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="模块类型">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">轮播图</el-radio>
          <el-radio :label="1">热门商圈</el-radio>
          <el-radio :label="2">推荐产品</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-col :span="10">
          <el-input v-model="form.title"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="序号(正序)" prop="sequence">
        <el-col :span="10">
          <el-input v-model="form.sequence"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>
  <el-dialog
    title="添加生活馆首页模块"
    v-model="mallVisible"
    width="600px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <el-radio-group v-model="mall_module_type">
        <el-radio label="0">轮播图</el-radio>
        <el-radio label="1">导航</el-radio>
        <el-radio label="2">单图</el-radio>
        <el-radio label="3">好物推荐</el-radio>
      </el-radio-group>
    </div>
    <template #footer>
      <el-button type="primary" @click="onOkSet">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
import MallBanner from "./components/mall/banner.vue";
import MallNav from "./components/mall/nav.vue";
import MallRecGoods from "./components/mall/rec_goods.vue";
import MallRowImage from "./components/mall/row_image.vue";

export default {
  components: {
    draggable,
    MallBanner,
    MallNav,
    MallRecGoods,
    MallRowImage,
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      //商城模块添加弹框
      mallVisible: false,
      version_id: "",
      versionList: [],
      current_version: {},
      //模块列表
      moduleList: [],
      form: {},
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //商城首页模块
      mallModuleList: [],
      //编辑商城首页
      editMall: false,
      //选择添加的模块
      mall_module_type: "",
    };
  },
  created() {
    this.loadVersion();
  },
  methods: {
    /**
     * 获取版本
     */
    loadVersion() {
      this.$http.get("admin/v1/applet/version").then((res) => {
        if (res.code == 0) {
          this.versionList = res.data;
          if (res.data.length > 0) {
            this.version_id = res.data[0].id;
            this.current_version = res.data[0];
            this.loadData();
          }
        }
      });
    },

    /**
     * 加载板块数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("admin/v1/applet/module?versionId=" + this.version_id)
        .then((res) => {
          if (res.code == 0) {
            this.moduleList = res.data;
          }
          this.loading = false;
        });
      //商城首页模块
      this.$http
        .get("admin/v1/applet/module/mall?versionId=" + this.version_id)
        .then((res) => {
          if (res.code == 0) {
            this.mallModuleList = res.data;
          }
          this.loading = false;
        });
    },

    /**
     * 版本更改选择
     */
    versionChange() {
      this.current_version = this.versionList.find(
        (x) => x.id == this.version_id
      );
      this.loadData();
    },

    /**
     * 添加模块
     */
    addModule() {
      this.form = {};
      this.dialogVisible = true;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;
      this.form.version_id = this.version_id;
      this.$http.post("admin/v1/applet/module/edit", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
        this.saving = false;
        this.dialogVisible = false;
      });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("admin/v1/applet/module/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },

    /**
     * 编辑商城首页
     */
    editMallModule() {
      this.editMall = true;
    },
    /**
     * 保存
     */
    onSaveMall() {
      this.saving = true;
      this.$http
        .post("admin/v1/applet/module/mall/save", this.mallModuleList)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type:'success',
              message:res.msg
            })
            this.loadData();
          }
        });
    },
    /**
     * 取消
     */
    onCancelMall() {
      this.editMall = false;
      this.loadData();
    },
    /**
     * 添加模块确定
     */
    onOkSet() {
      this.mallModuleList.push({
        uid: new Date().getTime(),
        version_id: this.version_id,
        type: this.mall_module_type,
        title: "",
        icon: "",
        sequence: this.mallModuleList.length + 1,
        items: [],
      });
      this.mallVisible = false;
    },

    /**
     * 轮播模块回调
     */
    mallModuleChange(row) {
      var item = this.mallModuleList.find((x) => x.id == row.id);
      console.log(item);
      if (item) {
        item.items = row.items;
      }
      console.log(this.moduleList);
    },
  },
};
</script>
<style scoped>
.screen {
  width: 415px;
  max-width: 415px;
  height: 630px;
  border: 1px solid var(--el-border-color);
}
</style>